<template>
  <div class="AddDevis">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>


    <CRow>
      <CCol>
        <h1> Nouveau devis - {{client.name}} </h1>
      </CCol>
    </CRow>
    <CCard class="mt-3">
      <CCardBody>
        <CForm v-on:submit.prevent="addDevis()">


          <CRow>
            <CCol>
              <span> Client destinataire de votre devis : </span> <strong> {{client.name}} </strong><br>
            </CCol>
          </CRow>

          <hr>

          <CSelect
            :value.sync="newDevisExerciceComptable"
            label="Exercice associé"
            @input="$v.newDevisExerciceComptable.$touch()"
            :isValid="$v.newDevisExerciceComptable.$dirty ? !$v.newDevisExerciceComptable.$error : null"
            :options='exerciceComptableYears'
            custom
          />

          <CInput
            label="Intitulé de votre devis "
            v-model="newDevisTitle" type="text"
            placeholder="Renseignez l'intitulé de votre devis"
            maxlength="149"
            @input="$v.newDevisTitle.$touch()"
            :isValid="$v.newDevisTitle.$dirty ? !$v.newDevisTitle.$error : null"
          >
          </CInput>

          <CInput
            label="Adresse de réception"
            v-model="newDevisAddress" type="text"
            placeholder="Renseignez l'adresse de réception"
            maxlength="300"
            @input="$v.newDevisAddress.$touch()"
            :isValid="$v.newDevisAddress.$dirty ? !$v.newDevisAddress.$error : null"
          >
          </CInput>

          <CRow>
            <CCol sm="12">

              <div v-if="$browserDetect.isSafari">
                <b-form-group id="create-devis-date-group" label="Date" label-for="create-devis-date-input">
                  <b-form-datepicker
                    id="create-devis-date-input"
                    name="create-devis-date-input"
                    locale="fr"
                    :min="today"
                    :show-decade-nav="true"
                    placeholder="Choisissez une date"
                    v-model="newDevisDate" class="mb-2"
                    @input="$v.newDevisDate.$touch()"
                    :state="$v.newDevisDate.$dirty ? !$v.newDevisDate.$error : null">
                  </b-form-datepicker>
                  <small class="text-danger" v-if="!$v.newDevisDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </b-form-group>
              </div>
              <div v-else>
                <CInput
                  label="Date"
                  v-model="newDevisDate" type="date"
                  placeholder="AAAA-MM-DD"
                  @input="$v.newDevisDate.$touch()"
                  :isValid="$v.newDevisDate.$dirty ? !$v.newDevisDate.$error : null"
                >
                <template #description>
                  <div v-if="$browserDetect.isIE" class="text-info">
                    <small>Vous utilisez Internet Explorer, veuillez entrer votre
                    date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                  </div>
                  <small class="text-danger" v-if="!$v.newDevisDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </template>
                </CInput>
              </div>
            </CCol>
          </CRow>

          <CTextarea
            class="mt-2"
            label="Message sur le devis"
            v-model="newDevisMessageDevis" type="text"
            placeholder="Vous pouvez renseigner un message personnalisé sur le devis"
            maxlength="300"
            @input="$v.newDevisMessageDevis.$touch()"
            :isValid="$v.newDevisMessageDevis.$dirty ? !$v.newDevisMessageDevis.$error : null"
          >
          </CTextarea>


          <CRow class="mt-4">
            <CCol class="text-center">
              <CButton
                type="submit" :color="addDevisButtonStyle"
                shape="pill" block class="px-4" size="lg"
                :disabled="$v.newDevisTitle.$invalid || $v.newDevisAddress.$invalid || $v.newDevisDate.$invalid || $v.newDevisMessageDevis.$invalid || addDevisInProcess">
                <CSpinner size="sm" label="create priority spinner" v-if="addDevisInProcess"></CSpinner>
                {{ addDevisButtonText }}
              </CButton>
            </CCol>
          </CRow>

        </CForm>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'
import { APIUserConnected } from '@/api/APIUserConnected'
import { APIManagerConnected } from '@/api/APIManagerConnected'
import { isDate } from '@/validators/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'AddDevis',
  components: {
    Loading,
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    const today = this.$dayjs().format('YYYY-MM-DD')
    const currentYear = this.$dayjs().year()
    return {
      client: {
        collaborateur: {
          id: '',
          full_name: ''
        },
        is_domiciliation: false,
        address: '',
        email: '',
        id: '',
        is_firm: true,
        name: '',
        first_name: '',
        last_name: '',
        phone_country_code: '',
        phone_number: '',
        representant_legal: '',
        siren: '',
        is_different_payeur: false,
        is_payeur_firm: true,
        payeur_siren: '',
        payeur_representant_legal: '',
        payeur_name: '',
        payeur_address: '',
        user: null
      },
      isClientLoading: false,

      newDevisExerciceComptable: currentYear,
      newDevisTitle: '',
      newDevisAddress: '',
      newDevisDate: today,
      today: today,
      newDevisMessageDevis: '',

      addDevisButtonText: 'Créer votre devis',
      addDevisButtonStyle: 'outline-primary',
      addDevisInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isClientLoading) {
        return true
      }
      return false
    },
    exerciceComptableYears () {
      const currentYear = this.$dayjs().year()
      var years = [{'label': '', 'value': ''}]
      for (var i=currentYear-2; i<=currentYear+2; i++) {
        var value = String(i)
        years.push({'label': value, 'value': i})
      }
      return years
    }
  },
  validations: {
    newDevisExerciceComptable: {
      required,
    },
    newDevisTitle: {
      required,
      maxLength: maxLength(149),
      minLength: minLength(2)
    },
    newDevisAddress: {
      required,
      maxLength: maxLength(300),
      minLength: minLength(2)
    },
    newDevisDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    newDevisMessageDevis: {
      maxLength: maxLength(300),
      minLength: minLength(2)
    },
  },

  created: function() {
    this.getClient()
  },

  methods: {
    // ------------- Getters --------------
    getClient () {
      this.isClientLoading = true
      apiUserConnected.getClient(this.token, this.$route.params.id)
      .then((result) => {
        this.client = result.data
        this.newDevisAddress = this.client.address
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isClientLoading = false
      })
    },
    addDevis () {
      this.addDevisInProcess = true
      this.addDevisButtonText = "Ajout en cours"
      this.addDevisButtonStyle = 'secondary'
      apiManagerConnected.addDevis(this.token,
        this.$route.params.id, this.newDevisExerciceComptable,
        this.newDevisTitle, this.newDevisAddress,
        this.newDevisDate, this.newDevisMessageDevis)
      .then((result) => {
          this.$router.push('/modify-devis/' + result.data.id)
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addDevisInProcess = false
        this.addDevisButtonText = "Créer votre devis"
        this.addDevisButtonStyle = 'outline-primary'
      })
    }
  }
}
</script>
